import { useContext, useState, createContext, Dispatch } from "react";

import AppApi from "apis/app";
import AppStore from "stores/app";

import { LayoutMode, Themes } from "utils/const";
import { WorkspaceSvg } from "blockly";

export const store = new AppStore();
export const api = new AppApi(store);

interface ThemeType {
  themeName: Themes;
  setThemeName: Dispatch<Themes>;
}

interface LayoutType {
  layoutName: string;
  setLayoutName: Dispatch<LayoutMode>;
}

interface UserObject {
  userName: string;
  sessionDuration: number;
}

interface UserInfoType {
  userInfo: UserObject;
  setUserInfo: Dispatch<UserObject>;
}

interface DragAndDropContextType {
  sourceTreeId: string;
  currentTreeId: string;
  dragItems: string[];
}

interface AuthInfoType {
  isAuthenticated: boolean;
  setIsAuthenticated: Dispatch<boolean>;
}

interface StartupInfoType {
  loading: boolean;
  setLoading: Dispatch<boolean>;
}

interface VmtBlocklyInfoType {
  basementWorkspace: WorkspaceSvg | null;
  brickFactoryWorkspace: WorkspaceSvg | null;
}

interface SessionContextType {
  theme: ThemeType;
  layout: LayoutType;
  user: UserInfoType;
  auth: AuthInfoType;
  startup: StartupInfoType;
  dragAndDropContext: DragAndDropContextType;
  vmtBlocklyContext: VmtBlocklyInfoType;
}

export const CreateSessionContextType = (): SessionContextType => {
  const [themeName, setThemeName] = useState(Themes.Dark);
  const [layoutName, setLayoutName] = useState(LayoutMode.Construction);
  const [userInfo, setUserInfo] = useState({ userName: '', sessionDuration: 0 });
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const dragAndDropContext = { sourceTreeId: "", currentTreeId: "", dragItems: [] };
  const vmtBlocklyContext = { basementWorkspace: null, brickFactoryWorkspace: null };

  return {
    theme: {
      themeName,
      setThemeName
    },
    layout: {
      layoutName,
      setLayoutName
    },
    user: {
      userInfo,
      setUserInfo
    },
    auth: {
      isAuthenticated,
      setIsAuthenticated,
    },
    startup: {
      loading,
      setLoading,
    },
    dragAndDropContext,
    vmtBlocklyContext
  } as SessionContextType;
};


interface AppContextType {
  store: AppStore;
  api: AppApi;
  session: SessionContextType;
}

const AppContext = createContext<null | AppContextType>(null);

export const useAppContext = () => {
  const context = useContext(AppContext);
  return context as AppContextType;
};

export default AppContext;