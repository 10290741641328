// React
import { useState, useEffect, useCallback, useRef, memo } from 'react';

import Container from 'react-bootstrap/Container';
import "bootstrap/dist/css/bootstrap.css";

// Blockly
import * as Blockly from 'blockly/core';
import 'blockly/blocks';
// import { WorkspaceSearch } from '@blockly/plugin-workspace-search';
import { ScrollOptions, ScrollBlockDragger, ScrollMetricsManager } from '@blockly/plugin-scroll-options';


// CSS
import { css } from "aphrodite";

// VMT styles
import styles from 'components/Styles';

import { useSnackbar } from 'notistack';

// VMT Blockly
import BlocklyComponent from 'components/BlocklyComponent';
import { ToolBox as toolbox } from 'components/BasementComponent/BasementToolbox';
import 'blockly_vmt/vavanya-generator';

import { FloatingButton } from 'components/FloatingControls';
import DialogModal from 'components/Modals/DialogModal';
import { InteractionMode } from 'react-complex-tree';
import VmtTree from 'components/VmtTree';
import { Components, Colors, NodeType } from 'utils/const';
import useConstruction from 'utils/hooks/useConstruction';
// import useVmtSerializer from './serializer/useVmtSerializer';

// reactive (RxJS)
import {
    blocklyEventStore,
    selectedConstructionInfoStore,
    dataBusStore, useStore,

    // brickId2EditStore
} from 'stores';

import { useAppContext } from "app-context";
import { /*autorun,*/ reaction } from 'mobx';
import { useBasementBlocklyController } from './blockly_stuff/useBasementBlocklyController';

// const UPDATE_REQUIRED_FIELD = 'updateRequired';
const ME = Components.BASEMENT.key;
const initXml = '<xml/>';

export const BasementComponent = memo(({ hide = false }) => {
    console.log('>>> <BasementComponent/>');
    const { /*api,*/ store, session: { vmtBlocklyContext } } = useAppContext();
    const { loadConstruction, saveConstruction, updateBrickUsages } = useConstruction();

    const [blocklyWorkspace, setBlocklyWorkspace] = useState(null);
    const workspaceRef = useRef(null);
    const [labelPos, setLabelPos] = useState({ x: -100, y: -100 });

    const [amendState, setAmendState] = useState(false);

    const [origWsXml, setOrigWsXml] = useState();
    const [brickRestoreLocationNode, setBrickRestoreLocationNode] = useState(null);

    const [constructionInfo/*, setConstructionInfo*/] = useStore(selectedConstructionInfoStore);
    const [blocklyEvent/*, setBlocklyEvent*/] = useStore(blocklyEventStore);
    const [dataBus/*, setDataBusContent*/] = useStore(dataBusStore);

    const { enqueueSnackbar/*, closeSnackbar*/ } = useSnackbar();

    const {
        blocklyChangeListener,
        registerShortcuts,
        registerBlocklyContextMenuOptions,
        showSelectNodeModal,
        restoreBrick,
        closeModal,
        variablesFlyoutCallback,
        scrollToVisible
    } = useBasementBlocklyController();

    // eslint-disable-next-line no-unused-vars
    const highlightCurrentSelection = (block) => {
        const path = block.pathObject.svgPath;
        Blockly.utils.dom.addClass(path, css(styles.highlightedBlock));
    };

    // eslint-disable-next-line no-unused-vars
    const unhighlightCurrentSelection = (block) => {
        const path = block.pathObject.svgPath;
        Blockly.utils.dom.removeClass(path, css(styles.highlightedBlock));
    };

    useEffect(() => {
        workspaceRef.current = blocklyWorkspace;
        vmtBlocklyContext.basementWorkspace = blocklyWorkspace;
    }, [blocklyWorkspace, vmtBlocklyContext]);

    useEffect(() => {
        // const disposerBS = autorun(() => {
        //     console.log('>>> bricksData CHANGED <<<');
        //     // console.log('bricksById', store.brick.bricksById);
        //     // console.log('bricksById', [...store.brick.bricksById]);
        //     const bricksArray = [...store.brick.bricksById];
        //     // if (store.brick.size > -1) {
        //     if (bricksArray.length > 0) {
        //         updateControlLabels(workspaceRef.current);
        //     }
        // });
        const disposer = reaction(
            () => [...store.brick.bricksById],
            () => {
                console.log('>>> bricksData CHANGED <<<');
                const { current: workspace } = workspaceRef;
                // updateControlLabels(workspace);
                // synchAllBricks(workspace);
                const brick = store.brick.byId(store.brick.lastUpdatedBrickId);
                // no update in case of a brick deletion
                if (brick) {
                    updateBrickUsages(workspace, brick);
                }
                markDeletedBricks(workspace);
            });

        return () => {
            disposer();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store]);

    useEffect(() => {
        console.log('useEffect [dataBus]', dataBus);
        const { current: workspace } = workspaceRef;
        if (dataBus?.to === ME) {
            switch (dataBus?.from) {
                case Components.CODE_EDITOR.key:
                    if (dataBus?.content?.blockId === null) return;
                    const blockId = dataBus.content.blockId;
                    console.log('Block ID to select', blockId);
                    scrollToVisible(workspace, blockId);
                    break;
                default:
                    break;
            }
        }
    }, [dataBus, scrollToVisible]);

    const getBrickData = (vmtBlock) => {
        const vmtBlockId = vmtBlock.data ? JSON.parse(vmtBlock.data).id : vmtBlock.type;
        return store.brick.byId(vmtBlockId) || null;
    };

    const markDeletedBricks = (workspace) => {
        if (!workspace) return;
        console.log('updateControlLabels');
        // get VMT blocks
        const allBlocks = workspace.getAllBlocks(true);
        const vmtBlocks = allBlocks.filter(block => block.type === 'vmt_brick');

        vmtBlocks.forEach((vmtBlock) => {
            const brick = getBrickData(vmtBlock);
            if (brick === null) { // original brick is missing (was deleted)
                vmtBlock.setHighlighted(true);
                vmtBlock.setWarningText('The brick was deleted');
                vmtBlock.setColour('#FF1111');
                vmtBlock['vmt_deleted_'] = true;
            }
            else {
                // update restored brick
                if (vmtBlock?.vmt_deleted_ === true) {
                    vmtBlock.setHighlighted(false);
                    vmtBlock.setWarningText(null);
                    vmtBlock.setColour(Colors.BRICK_COLOR);
                    delete vmtBlock['vmt_deleted_'];
                }
            }
        });
    };

    const loadXmlToWorkspace = (wsXml) => {
        Blockly.Events.disable();
        const { current: workspace } = workspaceRef;
        try {
            workspace.trashcan.emptyContents();
            const xml = Blockly.utils.xml.textToDom(wsXml);
            Blockly.Xml.clearWorkspaceAndLoadFromXml(xml, workspace);
            workspace.trashcan.emptyContents();
            const currentWsXml = Blockly.Xml.workspaceToDom(workspace);
            setOrigWsXml(Blockly.Xml.domToText(currentWsXml));
            setAmendState(false);
        }
        finally {
            Blockly.Events.enable();
            workspace.fireChangeListener(new (Blockly.Events.get(Blockly.Events.FINISHED_LOADING))());
            // Blockly.Events.fire(new (Blockly.Events.get(Blockly.Events.FINISHED_LOADING))());
        }
    };

    const loadConstruction_local = async (constructionId) => {
        console.log('loadConstruction', constructionId);
        // setUpdateRequired(false);
        if (constructionId === null) {
            // Empty (prev.: New) construction
            loadXmlToWorkspace(initXml);
        }
        else {
            // Load construction form DB
            console.log(`Load construction form DB; constr. id: `, constructionId);
            const { current: workspace } = workspaceRef;
            await loadConstruction(workspace, constructionId.id);
            const currentWsXml = Blockly.Xml.workspaceToDom(workspace);
            setOrigWsXml(Blockly.Xml.domToText(currentWsXml));
            setAmendState(false);
            markDeletedBricks(workspace);
        }
    };

    // const getFunctionalStructure = () => {

    //     const getBlockType = (block) => {
    //         return block.type;
    //     };
    //     const getBlockDesc = (block) => {

    //         return {
    //             name: 'block name',
    //             extended: 'extended'
    //         }
    //     };
    //     const getBlockRef = (block) => {
    //         return '0xDEAFBEEF'
    //     };
    //     const getChildren = (block) => {
    //         const children = [];
    //         const blocklyChildren = block.getChildren();
    //         if (blocklyChildren.length > 0) {
    //             let nextBlock = blocklyChildren[0]
    //             while (nextBlock) {
    //                 children.push(nextBlock);
    //                 nextBlock = nextBlock.getNextBlock();
    //             }
    //         }
    //         return children;
    //     };
    //     const getFunc = (block, isRoot = false) => {
    //         console.log('----------------------', block.type);
    //         getChildren(block).forEach(childBlock => {
    //             console.log('   ', childBlock.type);
    //         })

    //         const blockJson = {
    //             type: getBlockType(block),
    //             // is_root: isRoot,
    //             // desc: getBlockDesc(block),
    //             // enabled: block.isEnabled(),
    //             // ref: getBlockRef(block),
    //             //args,
    //             funcs: getChildren(block).map(childBlock => getFunc(childBlock))
    //             // funcs: block.getChildren(false).map(childBlock => getFunc(childBlock))
    //         }
    //         return blockJson;
    //     };
    //     const ws = workspaceRef.current;
    //     const blocks = ws.getTopBlocks(false);
    //     // find the root block
    //     const baseTestBlock = blocks.find(block => block.type === 'base_test');
    //     const constructionJson = getFunc(baseTestBlock, true);
    //     console.log('constructionJson', constructionJson);
    // };

    const saveConstruction_local = useCallback(async () => {
        console.log('saveConstruction_local');
        const { current: workspace } = workspaceRef;
        const xmlDom = Blockly.Xml.workspaceToDom(workspace);

        const updatedConstruction = await saveConstruction(workspace, constructionInfo.id);
        if (updatedConstruction) {
            enqueueSnackbar('Construction was successfully updated', { variant: 'success', persist: false });
        } else {
            enqueueSnackbar('Could not update construction', { variant: 'error', persist: true });
        }

        setOrigWsXml(Blockly.Xml.domToText(xmlDom));
        setAmendState(false);

    }, [constructionInfo, enqueueSnackbar, saveConstruction]);

    const updateBrickRestoreLocation = (nodeItem) => {
        console.log('updateBrickRestoreLocation', nodeItem);
        setBrickRestoreLocationNode(nodeItem);
    }

    useEffect(() => {
        registerBlocklyContextMenuOptions();
        registerShortcuts(blocklyWorkspace);
    }, [blocklyWorkspace, registerBlocklyContextMenuOptions, registerShortcuts]);

    useEffect(() => {
        console.log('constructionInfo: ', constructionInfo);
        if (blocklyWorkspace !== null) {
            loadConstruction_local(constructionInfo);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blocklyWorkspace, constructionInfo/*, loadConstruction*/]);

    useEffect(() => {
        console.log('useEffect');
        if (blocklyWorkspace === null) return;

        // register VMT variable flyout
        blocklyWorkspace.registerToolboxCategoryCallback('VMT_VARIABLE', variablesFlyoutCallback);

        const resizeObserver = new ResizeObserver(([entry]) => {
            const rect = entry.target.getBoundingClientRect();
            setLabelPos({ x: rect.x + rect.width - 40, y: rect.y + 50 });
        });
        resizeObserver.observe(blocklyWorkspace.getInjectionDiv());

        // // worspace search
        // const workspaceSearch = new WorkspaceSearch(blocklyWorkspace);
        // workspaceSearch.init();

        // Initialize scrolling plugin
        const plugin = new ScrollOptions(blocklyWorkspace);
        plugin.init();

        return () => {
            resizeObserver.disconnect();
        };
    }, [blocklyWorkspace, variablesFlyoutCallback]);

    useEffect(() => {
        const { current: workspace } = workspaceRef;
        if (workspace) {
            const currentWsXml = Blockly.Xml.workspaceToDom(workspace);
            const currentWsXmlText = Blockly.Xml.domToText(currentWsXml);
            if (currentWsXmlText !== origWsXml) {
                setAmendState(true);
            }
            else {
                setAmendState(false);
            }
        }
    }, [blocklyEvent, origWsXml]);

    // Blockly event listener
    useEffect(() => {
        console.log('useEffect');
        if (blocklyWorkspace !== null) {
            blocklyWorkspace.removeChangeListener(blocklyChangeListener);
            blocklyWorkspace.removeChangeListener(Blockly.Events.disableOrphans);
            blocklyWorkspace.addChangeListener(blocklyChangeListener);
            blocklyWorkspace.addChangeListener(Blockly.Events.disableOrphans);

        }
        return () => {
            blocklyWorkspace?.removeChangeListener(blocklyChangeListener);
            blocklyWorkspace?.removeChangeListener(Blockly.Events.disableOrphans);
        }
    }, [blocklyChangeListener, blocklyWorkspace,]);

    if (hide) return null;

    return (
        <>
            <BlocklyComponent
                workspaceSetter={setBlocklyWorkspace}
                readOnly={false}
                trashcan={true}
                media={'media/'}
                horizontalLayout={false}
                toolboxPosition='start'
                grid={{
                    spacing: 25,
                    length: 3,
                    colour: '#ccc',
                    snap: true
                }}
                move={{
                    scrollbars: true,
                    drag: true,
                    wheel: true
                }}
                zoom={{
                    controls: true,
                    wheel: true,
                    startScale: 0.8,
                    maxScale: 4,
                    minScale: 0.25,
                    scaleSpeed: 1.1
                }}
                maxInstances={{ 'base_test': 1 }}
                renderer={'zelos'}
                initialXml={initXml}
                plugins={{
                    // These are both required.
                    'blockDragger': ScrollBlockDragger,
                    'metricsManager': ScrollMetricsManager,
                }}
            >
                {toolbox}
            </BlocklyComponent>
            {(constructionInfo !== null && amendState === true) &&
                (<FloatingButton
                    x={labelPos.x}
                    y={labelPos.y}
                    text='Save'
                    onClick={() => { saveConstruction_local() }}
                />)
            }
            {/* {constructionMode.state === ModeStates.EDIT_STATE &&
                (<FloatingButton
                    x={labelPos.x}
                    y={constructionMode.touched === true ? labelPos.y + 35 : labelPos.y}
                    text='New'
                    onClick={() => { setConstructionInfo(null) }}
                />)
            } */}
            {/*updateRequired &&
                (<FloatingButton
                    x={labelPos.x}
                    y={labelPos.y + 35}
                    text='Update'
                    onClick={() => { synchAllBricks(workspaceRef.current) }}
                />)
                */}
            <DialogModal
                title='Choose Location'
                show={showSelectNodeModal}
                handleClose={() => { closeModal() }}
                primaryBtnLabel='Select'
                onPrimary={() => { restoreBrick(brickRestoreLocationNode) }}
            >
                <Container className={`${css(styles.modalScrollingPane)} ${css(styles.appContainer)}`}>
                    <VmtTree
                        treeId={'vmt-bricks-dir-nodes-tree'}
                        treeLabel={'BrickRestoreTree'}
                        defaultInteractionMode={InteractionMode.ClickArrowToExpand}
                        treeItems={Object.keys(store.brickTree.treeItems).reduce((acc, key) => {
                            const treeItem = store.brickTree.treeItems[key];
                            if (treeItem.node_type === NodeType.Node) {
                                return { ...acc, [key]: { ...treeItem } };
                            }
                            return acc;
                        }, { root: { ...store.brickTree.treeItems['root'] } })}
                        onFocusItem={updateBrickRestoreLocation}
                    />
                </Container>
            </DialogModal>
        </>
    );
});
