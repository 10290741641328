
import PropTypes from 'prop-types';

// MUI Table stuff
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

// VMT
import DialogModal from 'components/Modals/DialogModal';


const TableDialogModal = ({ tableHeaderItems, tableContentItems, fieldNames, ...other }) => {
    // const { tableHeaderItems, tableContentItems,  ...other } = props;
    const [keyFieldName, ...dataFieldNames] = fieldNames; // !!! First element MUST be a field name referencing to the field with
                                                          // unique id  within tableContentItems array of objects

    console.log(tableContentItems);

    return (
        <DialogModal {...other} paddingTop={0}>
            <Paper sx={{ width: '100%', overflowX: 'initial' }}>
                {/* <TableContainer component={Paper}> */}
                <TableContainer sx={{ maxHeight: '50%', overflowX: 'initial' }}>
                    <Table stickyHeader aria-label="table items view">
                        <TableHead>
                            <TableRow>
                                {tableHeaderItems.map((headerName) => (
                                    <TableCell key={headerName}>{headerName}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableContentItems.map((row) => (
                                <TableRow
                                    key={row[keyFieldName]}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    {dataFieldNames.map(fieldName => (<TableCell style={row['cellStyle']} key={`${row[keyFieldName]}-${fieldName}`}>{row[fieldName]}</TableCell>))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </DialogModal>
    );
};

TableDialogModal.propTypes = {
    tableHeaderItems: PropTypes.array.isRequired,
    tableContentItems: PropTypes.array.isRequired,
    fieldNames: PropTypes.array.isRequired,
};

export default TableDialogModal;