import React, { useState, useEffect, useRef } from 'react';

// bootstrap
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.css";

import { auth } from 'utils/VApi';
import { useAppContext } from "app-context";

const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];
const idleTimeout = 1000 * 60 * 10;  // 10 minutes
const countdownDuration = 1000 * 60 * 3; // 3 minutes

const IdleMonitor = () => {
    const { session: { user: { userInfo: { sessionDuration } }, auth: { setIsAuthenticated }, startup: { setLoading } } } = useAppContext();
    const [tokenRefreshRate] = useState(sessionDuration - 10000);
    const [idleModal, setIdleModal_] = useState(false);
    const [isCountdown, setIsCountdown] = useState(false);
    const setIdleModal = (value) => {
        setIdleModal_(value);
        setIsCountdown(value);
    }
    const [logoutCountdown, setLogoutCountdown] = useState(0);
    const idleEvent = useRef();
    const tokenRefreshTimer = useRef();
    const logoutTimer = useRef();

    const tokenRefresh = () => {
        console.log('Refresh tokens');
        auth()
            .tokenRefresh()
            .then((res) => {
                console.log(res);
            });
    };

    const resetSessionTimeout = () => {
        if (!!idleEvent.current) clearTimeout(idleEvent.current);
        // show session warning modal and start logout countdown
        idleEvent.current = setTimeout(() => {
            unsubscribeEvents();
            setIdleModal(true);
        }, idleTimeout);
    };

    const extendSession = () => {
        setIdleModal(false);
        subscribeEvents();
        resetSessionTimeout();
        console.log('user wants to stay logged in');
    };

    const subscribeEvents = () => {
        events.forEach(event => window.addEventListener(event, resetSessionTimeout));
    };

    const unsubscribeEvents = () => {
        events.forEach(event => window.removeEventListener(event, resetSessionTimeout));
    };

    const logOut = React.useCallback(() => {
        setIdleModal(false);
        auth()
            .logout()
            .then(
                (res) => {
                    console.log(res);
                    setIsAuthenticated(false);
                    setLoading(false);
                }
            );
        console.log('logging out');
    }, [setIsAuthenticated, setLoading]);

    useEffect(() => {
        resetSessionTimeout();
        subscribeEvents();
        // set up token refresh timer
        tokenRefreshTimer.current = setInterval(tokenRefresh, tokenRefreshRate);

        return () => {
            unsubscribeEvents();
            if (!!tokenRefreshTimer.current) clearInterval(tokenRefreshTimer.current);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenRefreshRate]);

    useEffect(() => {
        if (isCountdown) {
            setLogoutCountdown(countdownDuration);
            logoutTimer.current = setInterval(() => {
                setLogoutCountdown(prev => {
                    // console.log(prev);
                    const current = prev - 1000
                    if (current <= 0) {
                        logOut();
                    }
                    return current;
                })
            }, 1000)
        }
        else {
            if (!!logoutTimer.current) clearInterval(logoutTimer.current);
        }
    }, [isCountdown, logOut]);

    return (
        <Modal
            show={idleModal}
            onHide={() => extendSession()}
            backdrop="static"
            keyboard={false}
        // contentClassName={css(styles.modalContainer)}
        >
            {/* <Modal.Header closeButton> */}
            <Modal.Header>
                <Modal.Title>Session expire warning</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Your session will expire in {new Date(logoutCountdown).toISOString().substring(14, 19)} minutes. Do you want to extend the session?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => logOut()}>Logout</Button>
                <Button variant="primary" onClick={() => extendSession()} active>Extend session</Button>
            </Modal.Footer>
        </Modal >
    );
}

export default IdleMonitor;