import { useState, useCallback, useRef, useMemo } from 'react';
// reactive (RxJS)
import {
    blocklyEventStore,
    brickId2EditStore,
    brickFactoryDataStore,
    dataBusStore, useStore
} from 'stores';
import { ModeStates, Components } from 'utils/const';
import * as Blockly from 'blockly/core';
// eslint-disable-next-line no-unused-vars
import { Workspace, WorkspaceSvg, Scope, Events, RegistryItem } from 'blockly';

import { useAppContext } from "app-context";

const ME = Components.BASEMENT.key;


export const useBasementBlocklyController = () => {

    const [brickRestorePayload, setBrickRestorePayload] = useState(null);
    const [showSelectNodeModal, setShowSelectNodeModal] = useState(false);
    const [selectedBlock, setSelectedBlock] = useState(null);
    const currentBlockRef = useRef(null);

    const { session: { vmtBlocklyContext } } = useAppContext();

    const [/*dataBus*/, setDataBusContent] = useStore(dataBusStore);
    const [, setBrickDataToProcess] = useStore(brickFactoryDataStore);
    const [/*blocklyEvent*/, setBlocklyEvent] = useStore(blocklyEventStore);
    const [, setBrickId2Edit] = useStore(brickId2EditStore);


    // restore brick(s)
    const closeModal = () => setShowSelectNodeModal(false);
    const showModal = () => setShowSelectNodeModal(true);

    const restoreBrick = useCallback((brickRestoreLocationNode) => {
        console.log('restoreBrick', brickRestoreLocationNode);
        // // brickFactoryDataStore.setData
        setBrickDataToProcess({
            mode: //brickMode,
            {
                state: ModeStates.NEW_STATE,
                touched: false
            },
            nodeId: brickRestoreLocationNode, //nodeIdToStore,
            brickId: null, // does not require when create new brick
            dataToStore: brickRestorePayload
        });
        closeModal();
    }, [brickRestorePayload, setBrickDataToProcess]);

    /**
     * Return the brick block if it was selected
     * @param {Blockly.Block} selectedBlock 
     * @returns {Blockly.Block | null}
     */
    const getVmtBrick = (/**@type {Blockly.Block}*/selectedBlock) => {
        if (selectedBlock?.type === 'vmt_brick') {
            return selectedBlock;
        }
        else if (selectedBlock?.type.startsWith('vmt_control')) {
            return selectedBlock.getSurroundParent();
        }
        return null;
    };

    /**
     * 
     */
    const registerBlocklyContextMenuOptions = useCallback(() => {
        const restoreBrick = (vmtBrick) => {
            console.log('restoreBrick', vmtBrick);
            const vmtBrickData = {
                id: JSON.parse(vmtBrick.data).id,
                brick_caption: vmtBrick.getFieldValue('brick_caption'),
            };
            const childBlocks = vmtBrick.getChildren();
            // get the current controls ids in its order
            let controlsData = [];
            if (childBlocks.length > 0 && childBlocks[0].type.startsWith('vmt_control')) {
                const children = childBlocks[0].getDescendants();
                const controlBlocks = children.filter(({ type }) => type.startsWith('vmt_control'));
                controlsData = controlBlocks.map(controlBlock => {
                    return {
                        _id: JSON.parse(controlBlock.data).id,
                        name: controlBlock.getFieldValue('CONTROL_NAME').trim(),
                        ID: JSON.parse(controlBlock.data).ID,
                    };
                });
            }
            console.log('vmtBrickId', vmtBrickData);
            console.log('controls', controlsData);

            // create new brick payload
            const dbBrickJson = {};
            dbBrickJson._id = vmtBrickData.id;
            dbBrickJson.brick_type = vmtBrickData.brick_caption;
            dbBrickJson.brick_caption = vmtBrickData.brick_caption;

            // form controls array
            dbBrickJson.controls = controlsData;
            console.log(dbBrickJson);
            setBrickRestorePayload(dbBrickJson);
            showModal();
        };

        const workspaceRestoreItem = {
            displayText: 'Restore Deleted Bricks',
            preconditionFn: function (scope) {
                const allBlocks = scope.workspace.getAllBlocks(true);
                // try to find at least one deleted brick in the workspace
                const vmtBrick = allBlocks.find(block => block.type === 'vmt_brick' && block['vmt_deleted_'] && block['vmt_deleted_'] === true);
                return vmtBrick !== undefined ? 'enabled' : 'hidden';
            },
            callback: function (scope) {
                console.log('workspace callback', scope);
            },
            scopeType: Blockly.ContextMenuRegistry.ScopeType.WORKSPACE,
            id: 'restore_brick_workspace',
            weight: 100,
        };
        if (Blockly.ContextMenuRegistry.registry.getItem(workspaceRestoreItem.id) !== null) {
            Blockly.ContextMenuRegistry.registry.unregister(workspaceRestoreItem.id);
        }
        Blockly.ContextMenuRegistry.registry.register(workspaceRestoreItem);

        // menu item for VMT brick (Restore Brick)
        let brickRestoreItem = { ...workspaceRestoreItem };
        brickRestoreItem.displayText = 'Restore Deleted Brick'
        brickRestoreItem.scopeType = Blockly.ContextMenuRegistry.ScopeType.BLOCK;
        brickRestoreItem.id = 'restore_brick_block';
        brickRestoreItem.preconditionFn = function (scope) {
            let vmtBrick = getVmtBrick(scope.block);
            if (vmtBrick && vmtBrick['vmt_deleted_'] && vmtBrick['vmt_deleted_'] === true) {
                return 'enabled';
            }
            return 'hidden';
        };
        brickRestoreItem.callback = function (scope) {
            console.log('brick callback', scope);
            restoreBrick(getVmtBrick(scope.block));
        };
        if (Blockly.ContextMenuRegistry.registry.getItem(brickRestoreItem.id) !== null) {
            Blockly.ContextMenuRegistry.registry.unregister(brickRestoreItem.id);
        }
        Blockly.ContextMenuRegistry.registry.register(brickRestoreItem);

        // menu item for VMT brick (Jump TO Brick)
        let brickJumpToItem = { ...workspaceRestoreItem };
        brickJumpToItem.displayText = 'Jump To Brick'
        brickJumpToItem.scopeType = Blockly.ContextMenuRegistry.ScopeType.BLOCK;
        brickJumpToItem.id = 'jump_to_brick';
        brickJumpToItem.preconditionFn = function (scope) {
            let vmtBrick = getVmtBrick(scope.block);
            if (vmtBrick && vmtBrick?.vmt_deleted_ !== true) {
                return 'enabled';
            }
            return 'hidden';
        };
        brickJumpToItem.callback = function (scope) {
            const vmtBrick = getVmtBrick(scope.block);
            const brickId = JSON.parse(vmtBrick.data).id
            console.log('Jump To brickId', brickId);
            setDataBusContent({ to: Components.BRICK_TREE.key, from: ME, content: { brickId } })
        };
        if (Blockly.ContextMenuRegistry.registry.getItem(brickJumpToItem.id) !== null) {
            Blockly.ContextMenuRegistry.registry.unregister(brickJumpToItem.id);
        }
        Blockly.ContextMenuRegistry.registry.register(brickJumpToItem);
    }, [setDataBusContent]);

    /**
     * Invokes menu item programmatically 
     * @param {RegistryItem | null} blocklyMenuItem 
     * @param {Scope} scope 
     * @returns {Boolean}
     */
    const invokeBlocklyMenuItem = (/**@type {RegistryItem | null}*/blocklyMenuItem, /**@type {Scope}*/scope) => {
        if (blocklyMenuItem?.preconditionFn(scope)) {
            blocklyMenuItem.callback(scope);
            return true;
        }
        return false;
    };

    /**
     * Register Blockly keyboard shortcuts
     * @param {Workspace} workspace
     */
    const registerShortcuts = useCallback((/**@type {Workspace}*/workspace) => {
        const jumpToBrickShortcut = {
            name: 'jumpToBrickShortcut',
            blocklyMenuItem: Blockly.ContextMenuRegistry.registry.getItem('jump_to_brick'),
            preconditionFn: function (/*workspace*/) {
                const jumpToBrickItem = this.blocklyMenuItem;
                if (selectedBlock && jumpToBrickItem &&
                    jumpToBrickItem.preconditionFn({ block: selectedBlock }) === 'enabled') {
                    return true;
                }
                return false;
            },
            callback: function (/*workspace*/) {
                const jumpToBrickItem = this.blocklyMenuItem;
                console.log('>>> jumpToBrickShortcut <<<', jumpToBrickItem);
                if (invokeBlocklyMenuItem(jumpToBrickItem, { block: selectedBlock })) {
                    return true;
                }
                return false;
            }
        };

        const restoreBrickShortcut = {
            name: 'restoreBrickShortcut',
            blocklyMenuItem: Blockly.ContextMenuRegistry.registry.getItem('restore_brick_block'),
            preconditionFn: function (/*workspace*/) {
                const restoreBrickItem = this.blocklyMenuItem;
                if (selectedBlock && restoreBrickItem &&
                    restoreBrickItem.preconditionFn({ block: selectedBlock }) === 'enabled') {
                    return true;
                }
                return false;
            },
            callback: function (/*workspace*/) {
                const restoreBrickItem = this.blocklyMenuItem;
                console.log('>>> restoreBrickShortcut  <<<', restoreBrickItem);
                if (invokeBlocklyMenuItem(restoreBrickItem, { block: selectedBlock })) {
                    return true;
                }
                return false;
            }
        };

        if (workspace) {
            // Register 'jump to brick' shortcut
            Blockly.ShortcutRegistry.registry.removeAllKeyMappings(jumpToBrickShortcut.name);
            Blockly.ShortcutRegistry.registry.register(jumpToBrickShortcut, true);
            const keyJ = Blockly.ShortcutRegistry.registry.createSerializedKey(
                Blockly.utils.KeyCodes.J/*, [Blockly.ShortcutRegistry.modifierKeys.Control]*/);
            Blockly.ShortcutRegistry.registry.addKeyMapping(keyJ, jumpToBrickShortcut.name);

            // Register 'restore brick' shortcut
            Blockly.ShortcutRegistry.registry.removeAllKeyMappings(restoreBrickShortcut.name);
            Blockly.ShortcutRegistry.registry.register(restoreBrickShortcut, true);
            const keyR = Blockly.ShortcutRegistry.registry.createSerializedKey(
                Blockly.utils.KeyCodes.R/*, [Blockly.ShortcutRegistry.modifierKeys.Control]*/);
            Blockly.ShortcutRegistry.registry.addKeyMapping(keyR, restoreBrickShortcut.name);
        }
    }, [selectedBlock]);

    /**
     * Provides the list of blocks for the variable's flyout
     * @param {WorkspaceSvg} workspace 
     * @returns {Element[]}
     */
    const variablesFlyoutCallback = (/**@type {WorkspaceSvg}*/workspace) => {
        const variableModelList = workspace.getVariablesOfType('');
        //const variables = Blockly.Variables.allUsedVarModels(workspace);
        var button = document.createElement('button');
        button.setAttribute('text', '%{BKY_NEW_VARIABLE}');
        button.setAttribute('callbackKey', 'CREATE_VARIABLE');

        workspace.registerButtonCallback('CREATE_VARIABLE', function (button) {
            Blockly.Variables.createVariableButtonHandler(button.getTargetWorkspace());
        });

        let xmlList = [];
        xmlList.push(button);

        if (variableModelList.length > 0) {
            // New variables are added to the end of the variableModelList.
            var mostRecentVariable = variableModelList[variableModelList.length - 1];
            let block;
            if (Blockly.Blocks['vmt_variables_set']) {
                block = Blockly.utils.xml.createElement('block');
                block.setAttribute('type', 'vmt_variables_set');
                block.setAttribute('gap', Blockly.Blocks['math_change'] ? 8 : 24);
                block.appendChild(
                    Blockly.Variables.generateVariableFieldDom(mostRecentVariable));
                xmlList.push(block);
            }
            /*
            if (Blockly.Blocks['math_change']) {
                var block = Blockly.utils.xml.createElement('block');
                block.setAttribute('type', 'math_change');
                block.setAttribute('gap', Blockly.Blocks['variables_get'] ? 20 : 8);
                block.appendChild(
                    Blockly.Variables.generateVariableFieldDom(mostRecentVariable));
                var value = Blockly.utils.xml.textToDom(
                    '<value name="DELTA">' +
                    '<shadow type="math_number">' +
                    '<field name="NUM">1</field>' +
                    '</shadow>' +
                    '</value>');
                block.appendChild(value);
                xmlList.push(block);
            }
            */
            if (Blockly.Blocks['vmt_variables_get']) {
                variableModelList.sort(Blockly.VariableModel.compareByName);
                for (var i = 0, variable; (variable = variableModelList[i]); i++) {
                    block = Blockly.utils.xml.createElement('block');
                    block.setAttribute('type', 'vmt_variables_get');
                    block.setAttribute('gap', 8);
                    block.appendChild(Blockly.Variables.generateVariableFieldDom(variable));
                    xmlList.push(block);
                }
            }
        }
        return xmlList;
    };

    //--------------------------------------
    let clickTimeStamp_ = 0;
    //--------------------------------------
    /**
     * Blockly change listener for Basement
     * @param {Events.Abstract} event
     */
    const changeListener = useCallback((/**@type {Events.Abstract}*/event) => {
        // to avoid 'bombarding' with Blockly events we skip VIEWPORT_CHANGE
        if (event.type === Blockly.Events.VIEWPORT_CHANGE) return;

        // const workspace = Blockly.Workspace.getById(event.workspaceId);
        const workspace = vmtBlocklyContext.basementWorkspace;
        setBlocklyEvent({ blocklyWorkspace: workspace, blocklyEvent: event });
        console.log('Basement >> Event >>', event);

        // delete all newly ceated blocks in case no construction is loaded
        if (event.type === Blockly.Events.CREATE) {
            console.log('Basement >> workspaceChange', event);
            const workspace = Blockly.Workspace.getById(event.workspaceId);
            const blocks = workspace.getTopBlocks(false);
            const baseTestBlock = blocks.find(block => block.type === 'base_test');
            if (baseTestBlock === undefined) {
                const block = workspace.getBlockById(event.blockId);
                block.dispose();
            }
        }
        if (event.type === Blockly.Events.SELECTED) {
            const workspace = Blockly.Workspace.getById(event.workspaceId);
            event.newElementId &&
                setDataBusContent({
                    to: Components.V_APP.key,
                    from: ME,
                    content: {
                        blockId: event.newElementId
                    }
                });
            const block = workspace.getBlockById(event.newElementId);
            // console.log('block:', block);
            // check if it is a VMT brick
            if (block?.type === 'vmt_brick') {
                const brickId = JSON.parse(block.data).id;
                setBrickId2Edit(brickId);
                setSelectedBlock(block);
            }
            else {
                if (block?.type.startsWith('vmt_control')) {
                    const parentBlock = block.getSurroundParent();
                    // parentBlock.select();
                    const brickId = JSON.parse(parentBlock.data).id;
                    setBrickId2Edit(brickId);
                    setSelectedBlock(parentBlock);
                }
                else {
                    setSelectedBlock(null);
                }
            }
        }
        // catering for double click
        else if (event.type === Blockly.Events.CLICK) {
            let clickTimeStamp = Date.now();
            if (clickTimeStamp_ !== 0) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                clickTimeStamp_ = 0;
                const workspace = Blockly.Workspace.getById(event.workspaceId);
                console.log(workspace);
                const block = workspace.getBlockById(event.blockId);
                // console.log(block, block.isCollapsed());
                // toggle the block collapse state
                block && block.setCollapsed(!block.isCollapsed());
                // console.log(block, block.isCollapsed());
                console.log("delta");
            }
            else {
                const workspace = Blockly.Workspace.getById(event.workspaceId);
                const block = workspace.getBlockById(event.blockId);
                // toggle the block collapse state
                block && block.isCollapsed() && block.setCollapsed(false);

                clickTimeStamp_ = clickTimeStamp;
                setTimeout(() => { clickTimeStamp_ = 0; }, 300);
            }
            setTimeout(() => {
                // hide all warning and comment pop-ups
                // TODO: should be available with new Blockly version
                // blocklyWorkspace.hideChaff();
                const allBlocks = workspace.getAllBlocks(true);
                const vmtBricks = allBlocks.filter(block => block.type === 'vmt_brick');
                vmtBricks.forEach(brick => {
                    brick.getIcon(Blockly.icons.IconType.WARNING)?.setBubbleVisible(false);
                    brick.getIcon(Blockly.icons.IconType.COMMENT)?.setBubbleVisible(false);
                    brick.getIcon(Blockly.icons.IconType.MUTATOR)?.setBubbleVisible(false);
                });
            }, 100);
        }
    }, [setBlocklyEvent]);

    /**
     * Scrolls the workspce to make the block with given id visible in the viewport
     * @param {WorkspaceSvg} blocklyWorkspace 
     * @param {String} blockId 
     */
    const scrollToVisible = (/**@type {WorkspaceSvg}*/blocklyWorkspace, /**@type {String}*/blockId) => {
        if (!blocklyWorkspace?.isMovable()) {
            // Cannot scroll to block in a non-movable workspace.
            return;
        }
        const block = blocklyWorkspace.getBlockById(blockId);
        if (!block) {
            // could not find the block
            return;
        }
        // XY is in workspace coordinates.
        const xy = block.getRelativeToSurfaceXY();
        const scale = blocklyWorkspace.scale;
        const yAxeOffset = 50 * scale;

        // Block bounds in pixels relative to the workspace origin (0,0 is centre).
        const width = block.width * scale;
        const height = block.height * scale;
        const top = xy.y * scale;
        const bottom = (xy.y + block.height) * scale;
        // In RTL the block's position is the top right of the block, not top left.
        const left = blocklyWorkspace.RTL ? xy.x * scale - width : xy.x * scale;
        const right = blocklyWorkspace.RTL ? xy.x * scale : xy.x * scale + width;

        const metrics = blocklyWorkspace.getMetrics();

        let targetLeft = metrics.viewLeft;
        const overflowLeft = left < metrics.viewLeft;
        const overflowRight = right > metrics.viewLeft + metrics.viewWidth;
        const wideBlock = width > metrics.viewWidth;

        if ((!wideBlock && overflowLeft) || (wideBlock && !blocklyWorkspace.RTL)) {
            // Scroll to show left side of block
            targetLeft = left;
        } else if ((!wideBlock && overflowRight) ||
            (wideBlock && this.workspace_.RTL)) {
            // Scroll to show right side of block
            targetLeft = right - metrics.viewWidth;
        }

        let targetTop = metrics.viewTop;
        const overflowTop = top < metrics.viewTop;
        const overflowBottom = bottom > metrics.viewTop + metrics.viewHeight;
        const tallBlock = height > metrics.viewHeight;

        if (overflowTop || (tallBlock && overflowBottom)) {
            // Scroll to show top of block
            targetTop = top - yAxeOffset;
        } else if (overflowBottom) {
            // Scroll to show bottom of block
            targetTop = bottom - metrics.viewHeight + yAxeOffset;
        }
        if (targetLeft !== metrics.viewLeft || targetTop !== metrics.viewTop) {
            const activeEl = document.activeElement;
            blocklyWorkspace.scroll(-targetLeft, -targetTop);
            // blocklyWorkspace.centerOnBlock(blockId);
            if (activeEl) {
                // Blockly.WidgetDiv.hide called in scroll is taking away focus.
                // TODO: Review setFocused call in Blockly.WidgetDiv.hide.
                activeEl.focus();
            }
        }
        // hightlight the block
        // block.setHighlighted(true);
        // block.select();
        const prevSelectedBlock = currentBlockRef.current;
        // prevSelectedBlock && prevSelectedBlock.setHighlighted(false);
        // prevSelectedBlock && unhighlightCurrentSelection(prevSelectedBlock);
        prevSelectedBlock && prevSelectedBlock.removeSelect();
        // block.setHighlighted(true);
        // highlightCurrentSelection(block);
        block.addSelect();
        currentBlockRef.current = block;
    };

    return useMemo(
        () => ({
            registerShortcuts: registerShortcuts,
            registerBlocklyContextMenuOptions: registerBlocklyContextMenuOptions,
            blocklyChangeListener: changeListener,
            variablesFlyoutCallback: variablesFlyoutCallback,
            scrollToVisible: scrollToVisible,
            restoreBrick: restoreBrick,
            closeModal: closeModal,
            showSelectNodeModal: showSelectNodeModal,
        }),
        [registerShortcuts, registerBlocklyContextMenuOptions, changeListener, restoreBrick, showSelectNodeModal]
    );
};