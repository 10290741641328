import * as Blockly from 'blockly/core';
// import { Workspace, setLocale, utils, Xml, serialization } from 'blockly/core';
import * as En from 'blockly/msg/en';
import { javascriptGenerator } from 'blockly/javascript';
import 'blockly/blocks';

import './vmt_blocks/brick_factory_blocks';
import './vmt_blocks/building_blocks';
import './vmt_blocks/actions';
import './vmt_blocks/values';
import './vmt_blocks/variables';

import { forBlock as wdioForBlock, vmtJSGenerator as wdioJSGenerator } from './generators/wdio/javascript';
import { forBlock as playwrightForBlock, vmtJSGenerator as playwrightJSGenerator } from './generators/playwright/javascript';

Blockly.setLocale(En);

javascriptGenerator.addReservedWords('__vmtLastRetVal__');

/**
 * Generate the code of the construction description string (XML or JSON) 
 * for the given target technology 
 * @param {string} constrDesc - The string content of the construction description XML or JSON
 * @param {"wdio"|"playwright"} targetTechnology - The target technology (default: "wdio")
 * @returns {string} Generated code
 */
const ToCode = (constrDesc, targetTechnology = "wdio") => {
    if (targetTechnology === "wdio") {
        Object.assign(javascriptGenerator.forBlock, wdioForBlock);
        Object.assign(javascriptGenerator, wdioJSGenerator);
    }
    else if (targetTechnology === "playwright") {
        Object.assign(javascriptGenerator.forBlock, playwrightForBlock);
        Object.assign(javascriptGenerator, playwrightJSGenerator);
    }
    // Create a headless workspace.
    const workspace = new Blockly.Workspace();

    // Try to load the construction description
    try {
        // Parse and load XML based description
        const xml = Blockly.utils.xml.textToDom(constrDesc);
        Blockly.Xml.domToWorkspace(xml, workspace);
    }
    catch (xmlEx) {
        // console.log('XML construction description was not loaded:', xmlEx);
        try {
            // Parse and load JSON based description
            Blockly.serialization.workspaces.load(JSON.parse(constrDesc), workspace);
        } catch (jsonEx) {
            // console.log('JSON construction description was not loaded:', jsonEx);
            return "Invalid construction description";
        }
    }

    // Generate the code for the headless workspace
    const code = javascriptGenerator.workspaceToCode(workspace);
    return code;
}

export { ToCode };