import * as Blockly from 'blockly/core';
import 'blockly/blocks';
import 'blockly_vmt/vavanya-generator';
import { useAppContext } from "app-context";

// Types required for JSDoc comments
// eslint-disable-next-line no-unused-vars
import { Workspace, Block } from 'blockly';
// eslint-disable-next-line no-unused-vars
import IBrick from 'types/brick';
// eslint-disable-next-line no-unused-vars
import IConstruction from 'types/construction';

const useConstruction = () => {

    const { api, store } = useAppContext();

    // /**
    //  * Returns the brick description for the brick Block
    //  * @param {Block} brickBlock 
    //  * @returns {IBrick} - the brick decription
    //  */
    // const getBrickDesc = (/**Block*/brickBlock) => {
    //     const vmtBlockId = brickBlock.data ? JSON.parse(brickBlock.data).id : brickBlock.type;
    //     return store.brick.byId(vmtBlockId) || null;
    // };


    // /**
    //  * Updates controls labels for the give brick Block
    //  * @param {Block} brickBlock - brick Block which controls to update
    //  * @param {IBrick} brickDesc - the brick description to update 
    //  */
    // const syncControlsLabels = (/**@type {Block}*/brickBlock, /**@type {IBrick}*/brickDesc) => {
    //     // get list of controls for the block
    //     const brickChildBlocks = brickBlock.getChildren();

    //     if (brickChildBlocks.length > 0 && brickChildBlocks[0].type.startsWith('vmt_control')) {
    //         // const brickDesc = getBrickDesc(brickBlock);
    //         const children = brickChildBlocks[0].getDescendants();
    //         const controlBlocks = children.filter(({ type }) => type.startsWith('vmt_control'));
    //         // synch controls' label values with actual from database
    //         controlBlocks.forEach(controlBlock => {
    //             const controlBlockDataId = JSON.parse(controlBlock.data).id;
    //             const controlDesc = brickDesc.controls.find(control => control.id === controlBlockDataId)
    //             const currentControlName = controlBlock.getFieldValue('CONTROL_NAME').trim();
    //             if (controlDesc && currentControlName !== controlDesc.name) {
    //                 controlBlock.setFieldValue(controlDesc.name, 'CONTROL_NAME');
    //             }
    //         });
    //         // TODO: there must be a way to improve the logic!!
    //         // align the text in controls
    //         const maxFieldLength = controlBlocks.reduce((maxLength, control) => {
    //             const controlNameLength = control.getFieldValue('CONTROL_NAME').trim().length;
    //             return Math.max(maxLength, controlNameLength)
    //         }, 0);
    //         // align the controls' lengths
    //         controlBlocks.forEach(controlBlock => {
    //             const currentControlName = controlBlock.getFieldValue('CONTROL_NAME').trim();
    //             const paddedControlName = currentControlName.padEnd(maxFieldLength);
    //             controlBlock.setFieldValue(paddedControlName, 'CONTROL_NAME');
    //         });
    //     }
    // };


    /**
     * Update controls positions
     * @param {Block} brickBlock - the brick block to      
     * @param {IBrick} brickDesc - the brick description to update update 
     */
    const syncBrickBlock = (/**@type {Block}*/brickBlock, /**@type {IBrick}*/brickDesc) => {
        console.log('synchBrick')
        // const brickDesc = getBrickDesc(brickBlock);
        const brickControls = brickDesc.controls;
        const workspace = brickBlock.workspace;

        // get list of controls for the block
        const childBlocks = brickBlock.getChildren();
        let controlBlocks = [];
        if (childBlocks.length > 0 && childBlocks[0].type.startsWith('vmt_control')) {
            const children = childBlocks[0].getDescendants();
            controlBlocks = children.filter(({ type }) => type.startsWith('vmt_control'));
        }

        /**@type {Object.<string, Block>}*/
        const actions = {};
        console.log('controls (before unplug): ', controlBlocks);
        controlBlocks.forEach(control => {
            // detach controls
            control.unplug();
            control.setParent(null);
            const actionBlock = control.getInputTargetBlock('ACTION');
            if (actionBlock) {
                const controlData = JSON.parse(control.data);
                actions[controlData.id] = actionBlock;
                // detach action block
                actionBlock.unplug();
                actionBlock.setParent(null);
            }
        });

        // dispose current control blocks
        controlBlocks.forEach(control => {
            control.dispose();
        });

        // TODO: there must be a way to improve the logic!!
        // align the text in controls
        const maxFieldLength = brickControls.reduce((maxLength, brickControl) => {
            const controlNameLength = brickControl.name.trim().length;
            return Math.max(maxLength, controlNameLength)
        }, 0);


        console.log('actions: ', actions);
        const actionControlIds = Object.keys(actions);
        let parentConnection = brickBlock.getInput('CONTROLS').connection;
        brickControls.forEach(({ id, anchor, name, type }, index, arr) => {
            // create new control block
            let controlBlock
            if (index === arr.length - 1) {
                controlBlock = workspace.newBlock('vmt_control_last');
            }
            else {
                controlBlock = workspace.newBlock('vmt_control');
            }
            // align the controls' lengths
            const paddedControlName = name.padEnd(maxFieldLength);
            controlBlock.setFieldValue(paddedControlName, 'CONTROL_NAME');

            controlBlock.data = JSON.stringify({ id, ID: anchor, name, type });
            // deletable="false" movable="false"
            controlBlock.setDeletable(false);
            controlBlock.setMovable(false);

            // connect to the brick or prev. control
            const childConnection = controlBlock.previousConnection;
            parentConnection.connect(childConnection);
            parentConnection = controlBlock.nextConnection;
            // Try to render the control - will throw an exception in headless mode
            if (typeof controlBlock.initSvg == "function") {
                controlBlock.initSvg();
                controlBlock.render();
            }
            // connect action(s) back if required
            if (actionControlIds.includes(id)) {
                const actionConnection = actions[id].outputConnection;
                const ctrlActionConnection = controlBlock.getInput('ACTION').connection;
                ctrlActionConnection.connect(actionConnection);
                // remove/dispose the action from the map
                delete actions[id];
            }
        });

        // dispose actions of the deleted control(s)
        Object.keys(actions).forEach(controlId => {
            console.log('--- Remainded actions:', controlId, actions[controlId]);
            actions[controlId].dispose();
        });
    };

    /**
     * Update brick usages based on new brick description.
     * @param {Workspace} workspace - workspace with a construction loaded 
     * @param {IBrick} newBrickDesc - the new brick description to update 
     */
    const updateBrickUsages = (/**@type {Workspace}*/workspace, /**@type {IBrick}*/newBrickDesc) => {
        if (!workspace) return;
        console.log('>> updateBrickUsages');

        const allBlocks = workspace.getAllBlocks(true);

        // Find all the brickBlocks which are based on the 'newBrickDesc'
        const brickBlocks = allBlocks.filter(block => {
            if (block.type === 'vmt_brick') {
                // brick Block
                const brickId = JSON.parse(block.data).id;
                return brickId === newBrickDesc.id;
            }
            return false;
        });

        brickBlocks.forEach((brickBlock) => {
            syncBrickBlock(brickBlock, newBrickDesc);
            // syncControlsLabels(brickBlock, newBrickDesc);
        });
    };

    /**
     * Returns the statiscs of bricks in the construction and controls with asssigned actions.
     * @param {Workspace} workspace - workspace with a construction loaded
     * @returns {{brickIds: string[], controlIds: string[]}} - object with arrays of brick ids in the construction and constrols' ids being used 
     */
    const getUsageStats = (/**@type {Workspace}*/workspace) => {
        const allBlocks = workspace.getAllBlocks(true);
        // List of the used bricks
        const brickBlocks = allBlocks.filter((block) => block.type === 'vmt_brick')
        const vmtBrickIds = [...new Set(brickBlocks.map(vmtBrick => JSON.parse(vmtBrick.data).id))];
        // List of used controls i.e. with action(s) assigned to them
        let actionControls = {};
        brickBlocks.forEach((brickBlock) => {
            const childBlocks = brickBlock.getChildren();
            // get list of controls for the block
            let controls = [];
            if (childBlocks.length > 0 && childBlocks[0].type.startsWith('vmt_control')) {
                const children = childBlocks[0].getDescendants();
                controls = children.filter(({ type }) => type.startsWith('vmt_control'));
            }
            // check for actions
            const actControls = controls.reduce((actionControls, ctrlBlock) => {
                if (ctrlBlock.getInputTargetBlock('ACTION')) {
                    const controlData = JSON.parse(ctrlBlock.data);
                    actionControls[controlData.id] = true;
                }
                return actionControls;
            }, {});
            actionControls = { ...actionControls, ...actControls };
        });
        const actionControlIds = Object.keys(actionControls);
        console.log('Controls with actions', actionControlIds);

        return {
            brickIds: vmtBrickIds,
            controlIds: actionControlIds
        };
    };

    /**
    * Load the construction (constructionId) into the workspace.
    * @param {Workspace} workspace - Blockly workspace with loaded construction
    * @param {string} constructionId - construction id
    */
    const loadConstruction = async (/**@type {Workspace}*/workspace, /**@type {string}*/constructionId) => {
        // Fetch the construction description
        const constrBlocklyDesc = await api.construction.getOne(constructionId);
        Blockly.Events.disable();
        try {
            // Try to load the construction description
            try {
                // 0. Try to parse and load as an XML based description
                // Blockly.Xml.domToWorkspace(Blockly.utils.xml.textToDom(constrBlocklyDesc), workspace);
                Blockly.Xml.clearWorkspaceAndLoadFromXml(Blockly.utils.xml.textToDom(constrBlocklyDesc), workspace);
            }
            catch (xmlEx) {
                try {
                    // 1. Try to parse and load as a JSON based description
                    Blockly.serialization.workspaces.load(JSON.parse(constrBlocklyDesc), workspace);
                } catch (jsonEx) {
                    // console.log('JSON construction description was not loaded:', jsonEx);
                    throw new Error(`Not supported construction description, constrcution id: ${constructionId}`);
                }
            }
        }
        finally {
            Blockly.Events.enable();
            workspace.fireChangeListener(new (Blockly.Events.get(Blockly.Events.FINISHED_LOADING))());
        }
    };

    /**
     * Save the construction (constructionId) in the workspace.
     * @param {Workspace} workspace - Blockly workspace with loaded construction
     * @param {string} constructionId - construction id
     */
    const saveConstruction = async (/**@type {Workspace}*/workspace, /**@type {string}*/constructionId) => {
        const topBlocks = workspace.getTopBlocks(false);
        const constructionBaseBlock = topBlocks.find(block => block.type === 'base_test');
        // Get usages statistics
        const stats = getUsageStats(workspace);
        // Save updated construction
        const wsJson = Blockly.serialization.workspaces.save(workspace);
        /**@type {IConstruction}*/
        const constrJson = {
            workspace_id: constructionBaseBlock.id, // TODO: do we really need it ??
            workspace_xml: JSON.stringify(wsJson),
            bricks: stats.brickIds,
            controls: stats.controlIds
        };
        // const updatedConstruction = 
        return await api.construction.update(constructionId, constrJson);
    };

    /**
     * Updates brick (brickId) usages in construction (constructionId) and save it.
     * @name updateConstruction
     * @param {string} constructionId - construction id to update
     * @param {string} brickId - brick id to update
     */
    const updateConstruction = async (/**@type {string}*/constructionId, /**@type {string}*/brickId) => {
        const brickDesc = store.brick.byId(brickId);
        // Create a headless workspace.
        const workspace = new Blockly.Workspace();
        // Disable blocks not attached
        // workspace.addChangeListener(Blockly.Events.disableOrphans);
        await loadConstruction(workspace, constructionId);
        try {
            // Blockly.Events.disable();
            // Update brick usage in the loaded construction
            updateBrickUsages(workspace, brickDesc);
            return await saveConstruction(workspace, constructionId);
        }
        finally {
            // Blockly.Events.enable();
            // workspace.removeChangeListener(Blockly.Events.disableOrphans);
            workspace.dispose();
        }
    };

    return {
        updateConstruction,
        updateBrickUsages,
        getUsageStats,
        saveConstruction,
        loadConstruction
    };
};

export default useConstruction;